import React, {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  faEnvelope,
  faEye,
  faLock,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClavaContext } from "../../../../config/contexts";
import { translate } from "../../../../config/translator";
import { connector } from "./redux";
import { emailOrTel, isRegistered } from "../../../../config/utils";
import LeftSide from "../LeftSide";
import ClavaButton from "../../../components/ClavaButton";
import ClavaTextInput from "../../../components/ClavaTextInput";
import client from "../../../../client";
import { IdentifierType } from "../../../../config/types";

const Login: React.FC<ConnectedProps<typeof connector>> = ({
  reset,
  login,
  loginStatus,
  user,
}) => {
  const { l } = useContext(ClavaContext);
  const { redirectAfter } = useParams();
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState<string>("");
  const [identifierType, setIdentifierType] = useState<IdentifierType>("both");
  const [password, setPassword] = useState<string>("");
  const [pwForgot, setPwForgot] = useState(false);
  const pwInput = useRef<HTMLInputElement>(null);
  const [loginViaSms, setLoginViaSms] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [identifierAvailable, setIdentifierAvailable] = useState(true);
  const [pwVisible, setPwVisible] = useState<boolean>(false);
  const mailValid = useMemo(
    () => loginStatus !== "mailGiven" && loginStatus !== "mailInvalid",
    [loginStatus]
  );
  useEffect(() => {
    reset();
  }, [reset]);
  useEffect(() => {
    if (isRegistered(user) && user?.email === identifier) {
      setTimeout(() => {
        navigate(redirectAfter || "/profile");
      }, 2000);
    }
  }, [user, identifier]);
  const onLogin = useCallback(() => {
    login(identifier, password);
  }, [identifier, login, password]);

  const onChangeEmail = useCallback((e: string) => {
    setIdentifierType(emailOrTel(e));
    setIdentifier(e);
  }, []);
  const onChangePw = useCallback((e: string) => {
    setPassword(e);
  }, []);
  const onTogglePWVisible = useCallback(() => {
    setPwVisible((v) => !v);
  }, []);
  const onKeyUpPw = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") onLogin();
    },
    [onLogin]
  );
  const onPwForgot = useCallback(() => {
    setPwForgot((pwf) => !pwf);
  }, []);
  const onLoginViaSms = useCallback(() => {
    client()
      .loginWithSMS(
        identifierType === "mail" ? { email: identifier } : { tel: identifier }
      )
      .then(() => {
        setLoginViaSms(true);
      });
  }, [identifier, identifierType]);

  const identifierCheckResult = useCallback(
    (res: boolean) => {
      setIdentifierAvailable(!res);
      if (!res) {
        setPasswordVisible(true);
        if (pwInput.current) {
          pwInput.current.focus();
        }
      } else {
        setPasswordVisible(false);
      }
    },
    [pwInput]
  );
  const onCheckIdentifier = useCallback(() => {
    if (identifierType === "mail") {
      client().emailAvailable(identifier).then(identifierCheckResult);
    } else if (identifierType === "tel") {
      client().telAvailable(identifier).then(identifierCheckResult);
    } else {
      setIdentifierAvailable(false);
    }
  }, [identifier, identifierCheckResult, identifierType]);

  const onKeyUpMail = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") onCheckIdentifier();
    },
    [onCheckIdentifier]
  );
  return (
    <div className="flex flex-row items-stretch justify-start">
      <LeftSide />
      {pwForgot ? (
        <div className="flex-1 m-4 flex flex-col items-center justify-center">
          <span className="text-lg font-bold">
            {translate("downloadAppToConfirm", l)}
          </span>
          <div className="my-12">
            <ClavaButton secondary onClick={onPwForgot}>
              {translate("back", l)}
            </ClavaButton>
          </div>
        </div>
      ) : (
        <div className="flex-1 m-4 flex flex-col items-stretch justify-between">
          <div className="my-8">
            <h5 className="text-xl font-bold text-center">
              {translate("login", l)}
            </h5>
            {loginStatus !== "ok" && (
              <span className="text-red font-bold">
                {translate(loginStatus, l)}
              </span>
            )}
            {user && isRegistered(user) && user.email === identifier && (
              <span className="text-green font-bold">
                {translate("logInSuccess", l)}
              </span>
            )}
            {!identifierAvailable && (
              <span className="text-red font-bold">
                {translate("mailInvalid", l)}
              </span>
            )}
          </div>

          <div className="my-4 max-w-96 min-w-96 self-center">
            <ClavaTextInput
              onChange={onChangeEmail}
              value={identifier}
              type="text"
              onKeyUp={onKeyUpMail}
              error={!mailValid}
              placeholder="max@mustermann.com"
              label={translate(
                identifierType === "both"
                  ? "mailOrTel"
                  : identifierType === "tel"
                  ? "telIdentifier"
                  : "mailAddress",
                l
              )}
              iconL={identifierType === "tel" ? faPhone : faEnvelope}
            />
            {loginViaSms ? (
              <div className="relative my-4 flex flex-col items-center justify-center max-w-96 self-center">
                <span className="text-center">
                  {translate("linkViaSmsAndMail", l)}
                </span>
              </div>
            ) : passwordVisible ? (
              <div className="my-4 max-w-96 min-w-96 self-center">
                <div className="relative">
                  <ClavaTextInput
                    onChange={onChangePw}
                    value={password}
                    type={pwVisible ? "text" : "password"}
                    error={!loginStatus}
                    placeholder="password"
                    iconL={faLock}
                    onKeyUp={onKeyUpPw}
                    innerRef={pwInput}
                  />
                  <button
                    className={`w-4 h-4 bottom-3.5 right-3 absolute ${
                      pwVisible ? " !text-primary" : ""
                    }`}
                    onClick={onTogglePWVisible}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </div>
                <div className="flex my-2 flex-row justify-between">
                  <button
                    className="border border-primary rounded-2xl py-1 px-2 bg-primary-30 text-xs"
                    type="button"
                    onClick={onLoginViaSms}
                  >
                    <span className="text-primary text-xs">
                      {translate("loginViaSms", l)}
                    </span>
                  </button>
                  <button
                    className="border-0 py-1"
                    type="button"
                    onClick={onPwForgot}
                  >
                    <span className="text-primary text-xs">
                      {translate("pwForgot", l)}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="my-4  max-w-64 self-center text-center flex flex-col items-center justify-start">
                <ClavaButton onClick={onCheckIdentifier}>
                  {translate("continue", l)}
                </ClavaButton>
              </div>
            )}
          </div>
          <div className="my-12 text-center flex flex-col items-center justify-start">
            <ClavaButton
              disabled={!identifierAvailable || !passwordVisible}
              onClick={onLogin}
            >
              {translate("login", l)}
            </ClavaButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(Login);
// rel o ad
