import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { connector } from "./redux";
import {
  dayToNumber,
  getClosestDate,
  numberToDay,
  sameDay,
} from "../../../config/utils";
import MatchDayElement, {
  MatchDayContextType,
  MatchDaysContext,
  SELECTED_DAY,
} from "./MatchDay";
import Loading from "../Loading";
import ClavaCalendar from "./ClavaCalendar";

const MatchDays: React.FC<ConnectedProps<typeof connector>> = ({
  matchDays,
  getToday,
  type,
  id,
  getBigger,
  getSmaller,
  getMonth,
  sports,
  selectedDate,
  shouldScroll,
  disabled,
  setSelectedDate,
}) => {
  const scrollView = useRef<HTMLDivElement>(null);
  const { date } = useParams();
  const swipeStart = useRef(false);
  const nearestDate = useRef(new Date());
  const hrefDate = useMemo<number | undefined>(() => {
    if (!date || Number.isNaN(parseInt(date, 10))) return undefined;
    return parseInt(date, 10);
  }, [date]);
  useEffect(() => {
    if (matchDays.length === 0) {
      if (selectedDate) {
        setSelectedDate(undefined);
      }
      if (!selectedDate && !hrefDate) {
        getToday(id, type, sports);
      }
      if (!selectedDate && hrefDate) {
        getToday(id, type, sports, numberToDay(hrefDate));
      }
    }
  }, [
    sports,
    hrefDate,
    getToday,
    id,
    matchDays.length,
    selectedDate,
    setSelectedDate,
    getMonth,
    type,
  ]);
  useEffect(() => {
    if (
      hrefDate &&
      (!selectedDate || hrefDate !== dayToNumber(selectedDate)) &&
      matchDays.length
    ) {
      setSelectedDate(numberToDay(hrefDate));
    } else if ((!selectedDate || !hrefDate) && matchDays.length && !disabled) {
      const closest = getClosestDate(matchDays);
      nearestDate.current = closest;
      setSelectedDate(closest);
    }
    // eslint-disable-next-line
  }, [hrefDate, setSelectedDate, matchDays.length, selectedDate, disabled]);

  const onLoadMonth = useCallback(
    (day: Date) => {
      getMonth(id, day, type, sports);
    },
    [getMonth, id, sports, type]
  );
  const onPressBigger = useCallback(() => {
    if (scrollView.current && matchDays && matchDays.length) {
      if (
        scrollView.current.scrollLeft +
          scrollView.current.getBoundingClientRect().width >=
        scrollView.current.scrollWidth - 10
      ) {
        shouldScroll.current = false;
        getBigger(id, matchDays[matchDays.length - 1], type, sports);
      } else {
        scrollView.current.scrollTo({
          left: Math.min(
            scrollView.current.scrollLeft + 300,
            scrollView.current.scrollWidth -
              scrollView.current.getBoundingClientRect().width
          ),
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [matchDays, shouldScroll, getBigger, id, type, sports]);
  const onPressSmaller = useCallback(() => {
    if (scrollView.current && matchDays && matchDays.length) {
      if (scrollView.current.scrollLeft === 0) {
        shouldScroll.current = false;
        getSmaller(id, matchDays[0], type, sports);
      } else {
        scrollView.current.scrollTo({
          left: Math.max(scrollView.current.scrollLeft - 300, 0),
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [matchDays, shouldScroll, getSmaller, id, type, sports]);

  const customSelectDate = useCallback(
    (day: number) => {
      if (!swipeStart.current) {
        shouldScroll.current = true;
        setSelectedDate(numberToDay(day));
      }
    },
    [setSelectedDate, shouldScroll]
  );

  const matchDaysRender = useMemo(
    () => [
      ...matchDays.map(
        (d) =>
          dayToNumber(d) +
          (selectedDate && sameDay(d, selectedDate) ? SELECTED_DAY : 0)
      ),
    ],
    [matchDays, selectedDate]
  );
  const matchDaysContextVal = useMemo<MatchDayContextType>(
    () => ({
      disabled,
      selectDate: customSelectDate,
    }),
    [disabled, customSelectDate]
  );
  useEffect(() => {
    requestAnimationFrame(() => {
      if (scrollView.current && selectedDate) {
        if (shouldScroll.current) {
          const buttons = document.getElementsByClassName("matchday");
          for (let i = 0; i < buttons.length; i++) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const item: HTMLButtonElement | null = buttons.item(i);

            if (
              item &&
              parseInt(item.getAttribute("data-date") ?? "-1", 10) ===
                dayToNumber(selectedDate) + SELECTED_DAY
            ) {
              scrollView.current.scrollTo({
                left: item.offsetLeft - scrollView.current.clientWidth / 2,
                top: 0,
                behavior: "smooth",
              });
              break;
            }
          }
        } else {
          shouldScroll.current = true;
        }
      }
    });
  }, [selectedDate, shouldScroll]);

  return (
    <div className="border-b max-w-full border-b-light-gray dark:border-b-light-gray-dark flex flex-row items-stretch justify-between sticky top-0 bg-bg-secondary dark:bg-bg-secondary-dark">
      <MatchDaysContext.Provider value={matchDaysContextVal}>
        <button
          type="button"
          className="px-1 flex flex-row items-center justify-center border-4 border-transparent hover:text-primary bg-input-bg dark:bg-input-bg-dark"
          onClick={onPressSmaller}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="w-3 h-3" />
        </button>
        <div
          className="flex flex-row items-stretch justify-start overflow-x-scroll max-w-full matchdays-container"
          ref={scrollView}
        >
          {matchDays.length === 0 ? (
            <Loading small />
          ) : (
            <>
              {matchDaysRender.map((md) => (
                <MatchDayElement day={md} key={`match-day-${md}`} />
              ))}
            </>
          )}
        </div>
        <button
          type="button"
          className="px-1 flex flex-row items-center justify-center border-4 border-transparent hover:text-primary bg-input-bg dark:bg-input-bg-dark"
          onClick={onPressBigger}
        >
          <FontAwesomeIcon icon={faChevronRight} className="w-3 h-3" />
        </button>
        <ClavaCalendar
          matchDays={matchDays}
          loadMonth={onLoadMonth}
          selectedDate={selectedDate}
        />
      </MatchDaysContext.Provider>
    </div>
  );
};
// r elo  ad

export default connector(MatchDays);
