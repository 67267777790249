import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  faEnvelope,
  faInfoCircle,
  faSquareUser,
  faTrophy,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectedProps } from "react-redux";
import { showTranslated, translate } from "../../../config/translator";
import { ClavaContext, ClavaRootContext } from "../../../config/contexts";
import { connector } from "./redux";
import { LanguageLocaleEnum } from "../../../client/api";
import ClavaDropdown, { DropdownItem } from "../ClavaDropdown";
import client from "../../../client";
import {
  BETA_ENDPOINT,
  DEV_ENDPOINT,
  STAG_ENDPOINT,
} from "../../../config/constants";
import ClavaImage from "../ClavaImage";

const logoPng = require("../../../assets/images/logo-app.png");
const logoPngWhite = require("../../../assets/images/logo-app-white.png");

const it = require("../../../assets/images/it.png");
const en = require("../../../assets/images/en.png");
const de = require("../../../assets/images/de.png");

const Header: React.FC<ConnectedProps<typeof connector>> = ({
  changeLang,
  languages,
  getLanguages,
  status,
}) => {
  const { l, user } = useContext(ClavaContext);
  const { theme } = useContext(ClavaRootContext);
  useEffect(() => {
    if (languages.length === 0 && status === "idle") {
      getLanguages();
    }
  }, [languages, getLanguages, status]);
  const isDev = useMemo(() => client().getEndpoint() === DEV_ENDPOINT, []);
  const isBeta = useMemo(() => client().getEndpoint() === BETA_ENDPOINT, []);
  const isStag = useMemo(() => client().getEndpoint() === STAG_ENDPOINT, []);

  const onSelectLang = useCallback(
    (lang: string | undefined) => {
      const lng = languages.find((la) => la.locale === lang);
      if (lng) changeLang(lng);
    },
    [changeLang, languages]
  );
  const linkClasses = useCallback(
    ({ isActive, isPending }: { isActive: boolean; isPending: boolean }) => {
      if (isActive)
        return "flex flex-row items-center text-primary p-6 border-4 border-l-transparent border-t-transparent border-r-transparent border-b-primary";
      if (isPending)
        return "flex flex-row items-center text-primary p-6 border-4 border-transparent";
      return "flex flex-row items-center p-6 border-4 border-transparent hover:text-primary";
    },
    []
  );
  const loggedIn = useMemo(() => !user.anonymous, [user.anonymous]);
  const languageItems = useMemo<DropdownItem<string>[]>(
    () =>
      languages.map<DropdownItem<string>>((lang) => ({
        key: lang.locale,
        label: showTranslated(lang.name, l),
      })),
    [l, languages]
  );
  return (
    <div
      className={`rounded-xl max-w-full mb-4 mx-4 bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-lg ${
        isBeta ? "!bg-orange" : isStag ? "!bg-win" : isDev ? "!bg-loss" : ""
      }`}
    >
      <div className="flex flex-row max-w-6xl mx-auto items-center justify-start my-0 px-2">
        <NavLink to="/home" className="py-8 max-w-[130px]">
          <img
            src={theme === "dark" ? logoPng : logoPngWhite}
            alt="Clava Sports Logo"
            title="Clava Sports"
          />
        </NavLink>
        <div className="ml-6 flex flex-row items-center justify-start flex-1">
          <NavLink to="/home" className={linkClasses}>
            <FontAwesomeIcon icon={faTrophy} className="w-6 h-6 mr-1" />
            <span>{translate("matches", l)}</span>
          </NavLink>
          <NavLink to="mailto:info@clava-sports.com" className={linkClasses}>
            <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6 mr-1" />
            <span>{translate("contactUs", l)}</span>
          </NavLink>
          <NavLink
            target="_blank"
            to="https://info.clava-sports.com"
            className={linkClasses}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="w-6 h-6 mr-1" />
            <span>{translate("aboutUs", l)}</span>
          </NavLink>
        </div>
        <div className="py-4 flex flex-row items-center">
          <label htmlFor="lang">
            <img
              className="w-6 h-6"
              src={
                l === LanguageLocaleEnum.DE
                  ? de
                  : l === LanguageLocaleEnum.EN
                  ? en
                  : it
              }
              alt={translate("language", l)}
            />
          </label>
          <ClavaDropdown
            onChange={onSelectLang}
            items={languageItems}
            transparent
            value={l}
            withoutChoose
          />
        </div>
        {loggedIn ? (
          <NavLink to="/profile" className={linkClasses}>
            {user.photo ? (
              <div className="rounded-full overflow-hidden mr-1">
                <ClavaImage image={user.photo} width={24} />
              </div>
            ) : (
              <FontAwesomeIcon icon={faSquareUser} className="w-6 h-6 mr-1" />
            )}
            <span className="font-semibold">{user.givenName}</span>
          </NavLink>
        ) : (
          <NavLink to="/login" className={linkClasses}>
            <FontAwesomeIcon icon={faSquareUser} className="w-6 h-6  mr-1" />
            <span>{translate("login", l)}</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};
// reload
export default connector(Header);
