import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-regular-svg-icons";
import TeamName from "../../Team/TeamName";
import MatchStatusDisplay from "../MatchStatusDisplay";
import { isAdmin, matchStatusDate } from "../../../../config/utils";
import { translate } from "../../../../config/translator";
import { connector } from "./redux";
import { ClavaContext } from "../../../../config/contexts";
import Match from "../index";
import { parseParams } from "../../../../config/routes";
import { SportEnum } from "../../../../client/api";
import { MatchSmallElement } from "../../../../config/types";

const MatchSmall: React.FC<{ match: MatchSmallElement }> = ({ match }) => {
  const { user, l } = useContext(ClavaContext);
  const params = useParams();
  const { matchId } = params;

  const [status, setStatus] = useState(
    matchStatusDate(match.startTime, match.fullMatchLength, match.endTime)
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setStatus(
        matchStatusDate(match.startTime, match.fullMatchLength, match.endTime)
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [match.endTime, match.fullMatchLength, match.startTime]);
  const realMatchId = useMemo(() => {
    if (!matchId) return -1;
    const id = parseInt(matchId, 10);
    return Number.isNaN(id) ? -1 : id;
  }, [matchId]);
  const live = typeof status === "number";
  if (realMatchId === match.id) {
    return <Match match={match} />;
  }
  return (
    <NavLink
      to={parseParams({ matchId: match.id }, params)}
      className="flex flex-row items-stretch justify-start my-2 mx-4"
    >
      <div
        className={`min-w-20 max-w-20 flex flex-col bg-bg dark:bg-bg-dark items-center justify-center rounded-l-xl border border-light-gray dark:border-light-gray-dark ${
          status !== "past" ? "!bg-primary-20 !border-primary" : ""
        }`}
      >
        {match.cancelled ? (
          <span className="text-red text-sm">
            {translate("cancelledShort", l)}
          </span>
        ) : (
          <MatchStatusDisplay
            startDate={match.startTime}
            endTime={match.endTime}
            sectionAmount={match.sectionAmount}
            sectionDuration={match.sectionDuration}
            pauseDuration={match.pauseDuration}
            hideLive={match.sports === SportEnum.SOCCER}
            hideMinute={match.sports === SportEnum.HOCKEY}
          />
        )}
      </div>
      <div className="bg-bg dark:bg-bg-dark py-2 px-4 flex-1 border-t border-b border-t-light-gray border-b-light-gray dark:border-t-light-gray-dark dark:border-b-light-gray-dark flex flex-col items-stretch justify-between">
        <TeamName
          team={match.team1}
          goal={status === "future" ? undefined : match.goal1}
          live={live}
        />
        <TeamName
          team={match.team2}
          goal={status === "future" ? undefined : match.goal2}
          live={live}
        />
      </div>
      <button
        type="button"
        className="bg-bg dark:bg-bg-dark min-w-20 max-w-20 outline-0 flex flex-col items-center justify-center rounded-r-xl border border-light-gray dark:border-light-gray-dark "
      >
        {isAdmin(user) && <span className="text-muted">{`[${match.id}]`}</span>}
        <FontAwesomeIcon icon={faStar} className="w-6 h-6" />
      </button>
    </NavLink>
  );
};

const MatchSmallWrapper: React.FC<ConnectedProps<typeof connector>> = ({
  match,
}) => {
  if (match) return <MatchSmall match={match} />;
  return null;
};

export default connector(MatchSmallWrapper);
// reload
