import {Reducer} from "redux";
import {UserState} from "../constants";

import {fb} from "../../config/firebase";
import {convertFavorites, UserActions, UserActionTypes,} from "../actions/types";
import {SportEnum} from "../../client/api";
import {AS_SPORTS_TYPE} from "../../config/constants";

const initialState: UserState = {
  value: null,
  values: [],
  favorites: [],
  areaOfInterest: null,
  language: null,
  error: null,
  thumb: null,
  status: "idle",
  registerStatus: "ok",
  favoriteStatus: "idle",
  confirmMailStatus: "ok",
  confirmTelStatus: "ok",
  insiderStatus: "ok",
  loginStatus: "ok",
  userStatus: "ok",
  versionOk: true,
  sportsType: SportEnum.SOCCER,
};
const reducer: Reducer<UserState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: UserActions
) => {
  switch (action.type) {
    case UserActionTypes.RESET_FORM:
      return {
        ...state,
        registerStatus: "ok",
        loginStatus: "ok",
        insiderStatus: "ok",
        userStatus: "ok",
      };
    case UserActionTypes.ADD_FAVORITE:
      return { ...state, favoriteStatus: "loading" };

    case UserActionTypes.ADD_FAVORITE_FAILED:
      return { ...state, favoriteStatus: "failed" };

    case UserActionTypes.REFRESH:
    case UserActionTypes.GET_BASE_DATA:
    case UserActionTypes.GET_VERSION:
    case UserActionTypes.CHECK_VERSION:
    case UserActionTypes.REMOVE_FAVORITE:
    case UserActionTypes.FETCH_ME:
    case UserActionTypes.SET_AOI:
    case UserActionTypes.PATCH_USER:
    case UserActionTypes.LOGIN:
    case UserActionTypes.MANAGE_USER:
    case UserActionTypes.SET_LANGUAGE:
    case UserActionTypes.REGISTER: {
      return { ...state, status: "loading" };
    }
    case UserActionTypes.SET_SPORTS_TYPE: {
      localStorage.setItem(AS_SPORTS_TYPE, action.payload);
      return { ...state, sportsType: action.payload };
    }
    case UserActionTypes.LOGOUT: {
      return {
        ...state,
        status: "failed",
        registerStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        insiderStatus: "ok",
        favorites: [],
        favoriteStatus: "idle",
        values: [],
        value: null,
        error: "not_registered",
      };
    }
    case UserActionTypes.DELETE_ME_SUCCESS: {
      return {
        ...state,
        status: "failed",
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        favorites: [],
        favoriteStatus: "idle",
        values: [],
        value: null,
        error: "not_registered",
      };
    }
    case UserActionTypes.CHECK_VERSION_SUCCESS: {
      return { ...state, status: "idle", versionOk: !action.payload };
    }
    case UserActionTypes.REGISTER_FORM_INVALID: {
      return {
        ...state,
        status: "idle",
        error: null,
        registerStatus: action.payload,
      };
    }
    case UserActionTypes.USER_FORM_INVALID: {
      return {
        ...state,
        status: "idle",
        error: null,
        userStatus: action.payload,
      };
    }
    case UserActionTypes.LOGIN_FORM_INVALID: {
      return {
        ...state,
        status: "idle",
        error: null,
        loginStatus: action.payload,
      };
    }
    case UserActionTypes.INSIDER_FORM_INVALID: {
      return {
        ...state,
        status: "idle",
        error: null,
        insiderStatus: action.payload,
      };
    }
    case UserActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        status: "idle",
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        value: action.payload,
        favoriteStatus: "idle",
        favorites: [],
        areaOfInterest: action.payload.areaOfInterest,
        error: null,
      };
    }
    case UserActionTypes.MANAGE_USER_FAILED:
    case UserActionTypes.FETCH_ERROR: {
      return { ...state, status: "failed", error: action.payload };
    }
    case UserActionTypes.MANAGE_USER_SUCCESS: {
      let found = false;
      return {
        ...state,
        status: "idle",
        values: state.values
          .map((g) => {
            if (g.id === action.payload.id) {
              found = true;
              return action.payload;
            }
            return g;
          })
          .concat(found ? [] : [action.payload]),
      };
    }
    case UserActionTypes.BASE_DATA_SUCCESS: {
      return {
        ...state,
        status: "idle",
        error: null,
        ...action.payload,
      };
    }
    case UserActionTypes.UNAUTHORIZED: {
      return { ...state, status: "failed", error: "no_user_found" };
    }
    case UserActionTypes.REMOVE_FAVORITE_SUCCESS:
    case UserActionTypes.ADD_FAVORITE_SUCCESS: {
      const favs = convertFavorites(action.payload);
      fb().manageNotifications(favs).then();
      return {
        ...state,
        status: "idle",
        favoriteStatus: "idle",
        favorites: favs,
        error: null,
      };
    }
    case UserActionTypes.SET_AOI_SUCCESS: {
      return {
        ...state,
        status: "idle",
        areaOfInterest: action.payload,
        error: null,
      };
    }
    case UserActionTypes.SET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        status: "idle",
        language: action.payload,
        error: null,
      };
    }
    case UserActionTypes.BASE_DATA_MISSING: {
      return {
        ...state,
        status: "failed",
        error: "first_open",
      };
    }
    case UserActionTypes.PATCH_SUCCESS: {
      return {
        ...state,
        status: "idle",
        value: action.payload,
        formStatus: "ok",
        confirmTelStatus: "ok",
        confirmMailStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        registerStatus: "ok",
        userStatus: "ok",
        error: null,
      };
    }

    case UserActionTypes.PATCH_FAILED: {
      return {
        ...state,
        status: "failed",
        error: action.payload,
        userStatus: "failed",
        registerStatus: "failed",
        loginStatus: "failed",
        insiderStatus: "failed",
      };
    }
    case UserActionTypes.CONFIRM_TEL_SUCCESS: {
      return {
        ...state,
        status: "idle",
        value: action.payload,
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        confirmTelStatus: "confirmSuccess",
        userStatus: "ok",
        error: null,
      };
    }
    case UserActionTypes.CONFIRM_MAIL_SUCCESS: {
      return {
        ...state,
        status: "idle",
        value: action.payload,
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        confirmMailStatus: "confirmSuccess",
        error: null,
      };
    }
    case UserActionTypes.CONFIRM_MAIL_FAILED: {
      return {
        ...state,
        confirmMailStatus: "failed",
        error: action.payload,
      };
    }
    case UserActionTypes.CONFIRM_TEL_FAILED: {
      return {
        ...state,
        confirmTelStatus: "failed",
        error: action.payload,
      };
    }
    case UserActionTypes.PW_FORGOT: {
      return {
        ...state,
        status: "idle",
        error: null,
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        registerStatus: "ok",
      };
    }
    case UserActionTypes.PW_FORGOT_FAILED: {
      return {
        ...state,
        status: "idle",
        error: null,
        formStatus: "ok",
        insiderStatus: "ok",
        loginStatus: "ok",
        userStatus: "ok",
        registerStatus: "ok",
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as userReducer };
