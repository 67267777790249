import {ConnectedProps} from "react-redux";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from "react";
import {DeviceUUID} from "device-uuid";
import {Route, Routes} from "react-router";
import {faPalette} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobile} from "react-device-detect";
import {connector} from "./redux";
import EventsSocket from "../../../client/Websockets/events";
import {browserLang, getDeviceInfo, isAdmin} from "../../../config/utils";
import client from "../../../client";
import Loading from "../../components/Loading";
import {ClavaContext, ClavaRootContext, DropDownContext,} from "../../../config/contexts";
import {fb} from "../../../config/firebase";
import {AS_AOI, AS_ENDPOINT, AS_LANG, PROD_ENDPOINT,} from "../../../config/constants";
import {AdPositionEnum, User} from "../../../client/api";
import Header from "../../components/Header";
import Home from "../../screens/Home";
import Login from "../../screens/Profile/Login";
import Profile from "../../screens/Profile";
import {translate, TranslatorKeys} from "../../../config/translator";
import {loggerSettings} from "../../../store/middleware/logger";
import {DropDownContextType} from "../../../config/types";
import ClavaAd from "../../components/ClavaAd";
import MobilePopup from "../../components/MobilePopup";
import Imprint from "../../screens/Imprint";

const Main: React.FC<ConnectedProps<typeof connector>> = ({
  user,
  refreshToken,
  createUser,
  languageObject,
  aoi,
  error,
  initBaseDataUser,
  setTheme,
  resetStoredData,
  status,
  getLeagues,
  sports,
}) => {
  const { fbToken, theme } = useContext(ClavaRootContext);
  const [dropDown, setDropDown] = useState<JSX.Element>();
  const toggleTheme = useCallback(() => {
    setTheme(theme === "dark" ? "light" : "dark");
  }, [setTheme, theme]);
  useEffect(() => {
    const endpoint = window.localStorage.getItem(AS_ENDPOINT);
    EventsSocket.init(endpoint ?? PROD_ENDPOINT);
    client(endpoint ?? PROD_ENDPOINT).setLang(browserLang());
    initBaseDataUser();
    return () => {
      EventsSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (status === "failed") {
      if (error === "not_registered") {
        createUser({
          deviceId: new DeviceUUID().get(),
          deviceInfo: JSON.stringify(getDeviceInfo()),
          areaOfInterestId: aoi?.id ?? 1,
          languageId: languageObject?.id ?? 1,
          firebaseToken: fbToken ?? undefined,
        });
      } else if (error === "no_user_found") {
        refreshToken();
      } else if (error === "first_open") {
        createUser({
          deviceId: new DeviceUUID().get(),
          deviceInfo: JSON.stringify(getDeviceInfo()),
          areaOfInterestId: aoi?.id ?? 1,
          languageId: languageObject?.id ?? 1,
          firebaseToken: fbToken ?? undefined,
        });
      }
    } else if (status !== "loading" && aoi && languageObject && !user) {
      refreshToken();
    }
  }, [
    status,
    error,
    aoi,
    languageObject,
    user,
    fbToken,
    createUser,
    refreshToken,
  ]);
  const aoiRef = useRef(user?.areaOfInterest.id ?? -1);
  useEffect(() => {
    if (user) {
      localStorage.setItem(AS_AOI, user.areaOfInterest.id.toString(10));
      localStorage.setItem(AS_LANG, user.language.id.toString(10));
      getLeagues(user.areaOfInterest.id, sports);
      fb().subscribeUserSpecific(user);
      fb().tagUser(user, sports);
      if (aoiRef.current === -1) aoiRef.current = user.areaOfInterest.id;
      if (aoiRef.current !== user.areaOfInterest.id) {
        resetStoredData();
      }
      EventsSocket.setUser(user.id, user.areaOfInterest.id);
      client().setLang(user.language.locale);
      loggerSettings.enabled = isAdmin(user);
    }
  }, [getLeagues, resetStoredData, sports, user]);
  const closeDropdown = useCallback(() => {
    setDropDown(undefined);
  }, []);
  const dropDownContextVal = useMemo<DropDownContextType>(
    () => ({
      setClose: closeDropdown,
      setOpen: (e) => {
        setDropDown(e);
      },
    }),
    [closeDropdown]
  );
  const clavaContext = useMemo(
    () =>
      user
        ? { l: user.language.locale, aoi: user.areaOfInterest.id, user }
        : {
            l: browserLang(),
            aoi: -1,
            user: {} as User,
          },
    [user]
  );

  if (!user) return <Loading />;
  return (
    <ClavaContext.Provider value={clavaContext}>
      <DropDownContext.Provider value={dropDownContextVal}>
        {isMobile ? (
          <MobilePopup />
        ) : (
          <div
            className={`max-w-full min-h-[100vh] ${theme} bg-bg dark:bg-bg-dark font-gilroy font-normal text-font dark:text-font-dark pt-4`}
          >
            <ClavaAd type={"WEB_BACKGROUND" as AdPositionEnum} />
            <Header />
            <div className="mx-4">
              <div className="rounded-xl max-w-6xl mx-auto mb-8 bg-bg-secondary dark:bg-bg-secondary-dark shadow-shadow dark:shadow-shadow-dark drop-shadow-lg">
                <Routes>
                  <Route path="*" element={<Home />} />
                  <Route index element={<Home />} />
                  <Route path="/home/:date" element={<Home />} />
                  <Route path="/imprint" element={<Imprint />} />
                  <Route path="/home/match/:matchId" element={<Home />} />
                  <Route
                    path="/home/tournament/:tournamentId"
                    element={<Home />}
                  />
                  <Route path="/home/match/:matchId/:view" element={<Home />} />
                  <Route path="/home/:date/match/:matchId" element={<Home />} />
                  <Route
                    path="/home/:date/match/:matchId/:view"
                    element={<Home />}
                  />
                  <Route path="/" element={<Home />} />
                  <Route path="/league/:leagueId" element={<Home />} />
                  <Route path="/league/:leagueId/:date" element={<Home />} />
                  <Route
                    path="/league/:leagueId/match/:matchId"
                    element={<Home />}
                  />
                  <Route
                    path="/league/:leagueId/match/:matchId/:view"
                    element={<Home />}
                  />
                  <Route
                    path="/league/:leagueId/:date/match/:matchId"
                    element={<Home />}
                  />
                  <Route
                    path="/league/:leagueId/:date/match/:matchId/:view"
                    element={<Home />}
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/:redirectAfter" element={<Login />} />
                </Routes>
              </div>
            </div>
            <button
              type="button"
              className="theme-switcher"
              onClick={toggleTheme}
            >
              <FontAwesomeIcon icon={faPalette} />
              <span>
                {translate(`theme${theme}` as TranslatorKeys, clavaContext.l)}
              </span>
              <div className="spacer" />
            </button>
            {!!dropDown && (
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="fixed inset-0 bg-light-gray opacity-20"
                  onClick={closeDropdown}
                />
                {dropDown}
              </>
            )}
          </div>
        )}
      </DropDownContext.Provider>
    </ClavaContext.Provider>
  );
};
// reload
export default connector(Main);
