/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoalTypeSumList } from '../models/GoalTypeSumList';
import type { LeagueList } from '../models/LeagueList';
import type { PlayerPositionEnum } from '../models/PlayerPositionEnum';
import type { SuccessCreate } from '../models/SuccessCreate';
import type { SuccessList } from '../models/SuccessList';
import type { SuccessPatch } from '../models/SuccessPatch';
import type { Team } from '../models/Team';
import type { TeamCreate } from '../models/TeamCreate';
import type { TeamListElement } from '../models/TeamListElement';
import type { TeamListElementList } from '../models/TeamListElementList';
import type { TeamPatch } from '../models/TeamPatch';
import type { TeamPatchAdmin } from '../models/TeamPatchAdmin';
import type { TournamentTeam } from '../models/TournamentTeam';
import type { Translation } from '../models/Translation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamService {
    /**
     * Get Team By Id
     * @param teamId
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static getTeamByIdTeamTeamIdGet(
        teamId: number,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Team
     * @param teamId
     * @param requestBody
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static patchTeamTeamTeamIdPatch(
        teamId: number,
        requestBody: TeamPatch,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Team
     * @param teamId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteTeamTeamTeamIdDelete(
        teamId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Teams
     * @param leagueId
     * @returns TeamListElementList Successful Response
     * @throws ApiError
     */
    public static getTeamsTeamGet(
        leagueId: number,
    ): CancelablePromise<TeamListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/',
            query: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Team
     * @param requestBody
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static createTeamTeamPost(
        requestBody: TeamCreate,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Team Admin
     * @param teamId
     * @param requestBody
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static patchTeamAdminTeamAdminTeamIdPatch(
        teamId: number,
        requestBody: TeamPatchAdmin,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/team/admin/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Team By Player And League
     * @param playerId
     * @param leagueId
     * @returns TeamListElement Successful Response
     * @throws ApiError
     */
    public static getTeamByPlayerAndLeagueTeamPlayerPlayerIdLeagueLeagueIdGet(
        playerId: number,
        leagueId: number,
    ): CancelablePromise<TeamListElement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/player/{player_id}/league/{league_id}',
            path: {
                'player_id': playerId,
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Add Player To Team
     * @param playerId
     * @param teamId
     * @param position
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static addPlayerToTeamTeamTeamIdAddPlayerIdPost(
        playerId: number,
        teamId: number,
        position: PlayerPositionEnum,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/{team_id}/add/{player_id}',
            path: {
                'player_id': playerId,
                'team_id': teamId,
            },
            query: {
                'position': position,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Remove Player From Team
     * @param playerId
     * @param teamId
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static removePlayerFromTeamTeamTeamIdRemovePlayerIdPost(
        playerId: number,
        teamId: number,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/{team_id}/remove/{player_id}',
            path: {
                'player_id': playerId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Player Position
     * @param playerId
     * @param teamId
     * @param position
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static changePlayerPositionTeamTeamIdChangePositionPositionPlayerIdPost(
        playerId: number,
        teamId: number,
        position: PlayerPositionEnum,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/{team_id}/change/position/{position}/{player_id}',
            path: {
                'player_id': playerId,
                'team_id': teamId,
                'position': position,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get League History
     * @param teamId
     * @returns LeagueList Successful Response
     * @throws ApiError
     */
    public static getLeagueHistoryTeamLeagueHistoryTeamIdGet(
        teamId: number,
    ): CancelablePromise<LeagueList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/league_history/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Take Over Emblem
     * @param teamFromId
     * @param teamToId
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static takeOverEmblemTeamTakeOverEmblemTeamFromIdTeamToIdPut(
        teamFromId: number,
        teamToId: number,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/team/take_over_emblem/{team_from_id}/{team_to_id}',
            path: {
                'team_from_id': teamFromId,
                'team_to_id': teamToId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tournament Team
     * @param teamId
     * @param tournamentId
     * @returns TournamentTeam Successful Response
     * @throws ApiError
     */
    public static getTournamentTeamTeamTeamIdTournamentTournamentIdGet(
        teamId: number,
        tournamentId: number,
    ): CancelablePromise<TournamentTeam> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/{team_id}/tournament/{tournament_id}',
            path: {
                'team_id': teamId,
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Goal Type Sums
     * @param teamId
     * @returns GoalTypeSumList Successful Response
     * @throws ApiError
     */
    public static getGoalTypeSumsTeamGoalTypeSumsTeamIdGet(
        teamId: number,
    ): CancelablePromise<GoalTypeSumList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/goal_type_sums/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Team Fact
     * @param teamId
     * @returns Translation Successful Response
     * @throws ApiError
     */
    public static getTeamFactTeamFactTeamIdGet(
        teamId: number,
    ): CancelablePromise<Translation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/fact/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Team Image
     * @param teamId
     * @returns Team Successful Response
     * @throws ApiError
     */
    public static deleteTeamImageTeamImageTeamIdDelete(
        teamId: number,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/team/image/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Team Successes
     * @param teamId
     * @returns SuccessList Successful Response
     * @throws ApiError
     */
    public static getTeamSuccessesTeamSuccessTeamIdGet(
        teamId: number,
    ): CancelablePromise<SuccessList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/success/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Team Success
     * @param teamId
     * @param requestBody
     * @returns SuccessList Successful Response
     * @throws ApiError
     */
    public static createTeamSuccessTeamSuccessTeamIdPost(
        teamId: number,
        requestBody: SuccessCreate,
    ): CancelablePromise<SuccessList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team/success/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Team Success
     * @param successId
     * @param requestBody
     * @returns SuccessList Successful Response
     * @throws ApiError
     */
    public static patchTeamSuccessTeamSuccessSuccessIdPatch(
        successId: number,
        requestBody: SuccessPatch,
    ): CancelablePromise<SuccessList> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/team/success/{success_id}',
            path: {
                'success_id': successId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Team Success
     * @param successId
     * @returns SuccessList Successful Response
     * @throws ApiError
     */
    public static deleteTeamSuccessTeamSuccessSuccessIdDelete(
        successId: number,
    ): CancelablePromise<SuccessList> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/team/success/{success_id}',
            path: {
                'success_id': successId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Team Leagues
     * @param teamId
     * @param mainOnly
     * @returns LeagueList Successful Response
     * @throws ApiError
     */
    public static getTeamLeaguesTeamLeaguesTeamIdGet(
        teamId: number,
        mainOnly: boolean = true,
    ): CancelablePromise<LeagueList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team/leagues/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'main_only': mainOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
