import {ConnectedProps} from "react-redux";
import React, {useCallback, useContext, useEffect, useMemo, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleDown, faChevronCircleUp,} from "@fortawesome/pro-light-svg-icons";
import {faCircleDown, faCircleSort, faCircleUp,} from "@fortawesome/pro-solid-svg-icons";
import {connector} from "./redux";
import {ClavaContext} from "../../../config/contexts";
import {AdPositionEnum, MatchLocationEnum, StandingSmall,} from "../../../client/api";
import {showTranslated, translate} from "../../../config/translator";
import ClavaImage from "../ClavaImage";
import ClavaAd from "../ClavaAd";
import {IDType} from "../../../config/types";
import {isAdmin, isLive} from "../../../config/utils";

type TableStateType = {
  id: IDType;
  state: "WIN" | "DRAW" | "LOSS";
};

const Line: React.FC<{ standing: StandingSmall; state?: TableStateType }> = ({
  standing,
  state,
}) => {
  const { l, user } = useContext(ClavaContext);
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, []);
  const pbStyle = useMemo(() => {
    if (open) return "pb-14";
    return "";
  }, [open]);

  const showIds = useMemo(() => localStorage.getItem("showIds") === "yes", []);
  return (
    <tr className="border-b border-b-light-gray dark:border-b-light-gray-dark relative">
      <td className={`relative text-right pr-3 py-2 ${pbStyle}`}>
        {state ? (
          <FontAwesomeIcon
            icon={
              state.state === "WIN"
                ? faCircleUp
                : state.state === "LOSS"
                ? faCircleDown
                : faCircleSort
            }
            className={`absolute w-3 h-3 rounded-full top-2 left-2 ${
              state.state === "WIN"
                ? "text-win"
                : state.state === "LOSS"
                ? "text-loss"
                : "text-orange"
            }`}
          />
        ) : (
          <div />
        )}
        <span className="text-right">{standing.placement}</span>
      </td>
      <td className={`py-2 ${pbStyle}`}>
        <div className="flex flex-row items-center justify-start">
          <ClavaImage
            imageUrl={standing.team.emblemUrl}
            width={24}
            className="mr-2"
          />
          <span>
            {showIds && isAdmin(user) ? `[${standing.team.id}]` : ""}
            {showTranslated(standing.team.name, l)}
          </span>
        </div>
      </td>
      <td className={`text-right py-2 ${pbStyle}`}>{standing.matchesPlayed}</td>
      <td className={`text-right py-2 ${pbStyle}`}>
        {standing.goalsOut - standing.goalsIn}
      </td>
      <td className={`text-right py-2 ${pbStyle}`}>{standing.points}</td>
      <td className={`text-right py-2 pr-4 ${pbStyle}`}>
        <button type="button" onClick={toggleOpen}>
          <FontAwesomeIcon
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            className={`w-4 h-4 ${open ? "!text-primary" : ""}`}
          />
        </button>

        <div
          className={`absolute bottom-0 left-0 bg-bg dark:bg-bg-dark right-0 h-12 flex flex-row items-center justify-evenly ${
            open ? "" : "hidden"
          }`}
        >
          <span className="bg-win-30 rounded-lg py-0.5 px-8">{`${translate(
            "wins",
            l
          )}: ${standing.wins}`}</span>
          <span className="bg-loss-30 rounded-lg py-0.5 px-8">{`${translate(
            "losses",
            l
          )}: ${standing.losses}`}</span>
          <span className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "draws",
            l
          )}: ${standing.draws}`}</span>
          <span className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "goalsOut",
            l
          )}: ${standing.goalsOut}`}</span>
          <span className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "goalsIn",
            l
          )}: ${standing.goalsIn}`}</span>
        </div>
      </td>
    </tr>
  );
};

const Table: React.FC<ConnectedProps<typeof connector>> = ({
  leagueId,
  standing,
  getStanding,
  liveMatches,
}) => {
  const { l } = useContext(ClavaContext);
  const liveTeamIds = useMemo<TableStateType[]>(
    () =>
      liveMatches
        ? liveMatches.response.reduce<TableStateType[]>(
            (prev1, current1) =>
              prev1
                .concat(
                  current1.matches
                    .filter((m) => isLive(m))
                    .map((m) => ({
                      id: m.team1.id,
                      state:
                        m.goal1 === m.goal2
                          ? "DRAW"
                          : m.goal1 > m.goal2
                          ? "WIN"
                          : "LOSS",
                    }))
                )
                .concat(
                  current1.matches
                    .filter((m) => isLive(m))
                    .map((m) => ({
                      id: m.team2.id,
                      state:
                        m.goal1 === m.goal2
                          ? "DRAW"
                          : m.goal1 > m.goal2
                          ? "LOSS"
                          : "WIN",
                    }))
                ),
            []
          )
        : [],
    [liveMatches]
  );
  useEffect(() => {
    getStanding(leagueId);
  }, [getStanding, leagueId]);
  const selectedStanding = useMemo(() => {
    if (!standing || !standing.standings) return undefined;
    const st = standing.standings[MatchLocationEnum.ALL];
    if (st) {
      return st.sort((a, b) => a.placement - b.placement);
    }
    return undefined;
  }, [standing]);
  return (
    <div className="table-container">
      {selectedStanding ? (
        <div className="min-w-full">
          <ClavaAd type={AdPositionEnum.STANDINGS} />
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="text-right pr-3 py-2">#</th>
                <th className="text-left py-2">{translate("teams", l)}</th>
                <th className="text-right py-2">
                  {translate("matchesShort", l)}
                </th>
                <th className="text-right py-2">
                  {translate("difference", l)}
                </th>
                <th className="text-right py-2">{translate("points", l)} </th>
                <th className="py-2 pr-4"> </th>
              </tr>
            </thead>
            <tbody>
              {selectedStanding.map((st) => (
                <Line
                  standing={st}
                  key={`stand${st.team.id}`}
                  state={liveTeamIds.find((t) => t.id === st.team.id)}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <span>{translate("loading", l)}</span>
      )}
    </div>
  );
};

export default connector(Table);
// r elo ad
