import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { AreaOfInterest, SportEnum } from "../../../client/api";
import {
  MatchActionTypes,
  UserActionTypes,
} from "../../../store/actions/types";
import { performAction } from "../../../store/actions/all";
import { changeAoi } from "../../../store/actions/userActions";
import { fetchAois } from "../../../store/actions/aoiActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  changeSportsType: (sport: SportEnum) => {
    dispatch({ type: UserActionTypes.SET_SPORTS_TYPE, payload: sport });
    dispatch({ type: MatchActionTypes.RESET });
  },
  changeAoi: (aoi: AreaOfInterest) => {
    performAction({ f: changeAoi, p: [dispatch, aoi] });
  },
  getAois: () => {
    performAction({ f: fetchAois, p: [dispatch] });
  },
});

const props = (state: RootState, prevProps: { onChange?: () => void }) => ({
  currentSportsType: state.user.sportsType,
  aois: state.aois.value,
  aoisStatus: state.aois.status,
  ...prevProps,
});

export const connector = connect(props, mapper);
