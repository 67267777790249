import React, { useCallback, useContext } from "react";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Calendar, { CalendarTileProperties, ViewCallback } from "react-calendar";
import { useNavigate, useParams } from "react-router";
import { DAY_IN_MS, dayToNumber, sameDay } from "../../../../config/utils";
import { parseParams } from "../../../../config/routes";
import { DropDownContext } from "../../../../config/contexts";

type ClavaCalendarProps = {
  matchDays: Date[];
  loadMonth: (date: Date) => void;
  selectedDate: Date | undefined;
};

function isActive(dates: Date[], day: Date) {
  return !!dates.find((d) => sameDay(d, day));
}

const ClavaCalendar: React.FC<ClavaCalendarProps> = ({
  matchDays,
  selectedDate,
  loadMonth,
}) => {
  const { setOpen, setClose } = useContext(DropDownContext);
  const navigate = useNavigate();
  const params = useParams();
  const onDayPress = useCallback(
    (date: Date) => {
      if (date && isActive(matchDays, date)) {
        navigate(
          parseParams(
            {
              date: dayToNumber(date),
              matchId: undefined,
              view: undefined,
            },
            params
          )
        );
        setClose();
      }
    },
    [matchDays, navigate, params, setClose]
  );
  const renderTile = useCallback<
    (props: CalendarTileProperties) => JSX.Element | null
  >(
    ({ date, view }) => {
      if (view === "month") {
        if (!isActive(matchDays, date)) {
          return (
            <div className="absolute inset-0 bg-bg-secondary dark:bg-bg-secondary-dark opacity-50 !cursor-default" />
          );
        }
        return <div className="absolute inset-0 bg-transparent" />;
      }
      return null;
    },
    [matchDays]
  );
  const onActiveStartDateChange = useCallback<ViewCallback>(
    ({ action, activeStartDate, view }) => {
      if (
        (action === "prev" ||
          action === "prev2" ||
          action === "next" ||
          action === "next2") &&
        view === "month"
      ) {
        loadMonth(new Date(activeStartDate.getTime() + DAY_IN_MS));
      }
    },
    [loadMonth]
  );

  const onOpenCalendar = useCallback(() => {
    setOpen(
      <Calendar
        onClickDay={onDayPress}
        value={selectedDate}
        className="absolute top-24 left-[50%] ml-[-12rem] max-w-96 min-w-96 bg-bg-secondary dark:bg-bg-secondary-dark rounded-xl shadow-shadow dark:shadow-shadow-dark drop-shadow-xl p-4"
        tileContent={renderTile}
        onActiveStartDateChange={onActiveStartDateChange}
      />
    );
  }, [onActiveStartDateChange, onDayPress, renderTile, selectedDate, setOpen]);
  return (
    <button
      className="px-3 flex flex-row items-center justify-center border-0 outline-0"
      type="button"
      onClick={onOpenCalendar}
    >
      <FontAwesomeIcon icon={faCalendar} />
    </button>
  );
};

export default ClavaCalendar;
// rel o ad
