import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { performAction } from "../../../store/actions/all";
import { RootState } from "../../../store";
import {
  IDType,
  LeagueMatchSmallElement,
  MatchSmallElement,
} from "../../../config/types";
import { dayToNumber } from "../../../config/utils";
import { SportEnum, TournamentMinimal } from "../../../client/api";
import {
  fetchLeagueMatchesOfDay,
  fetchLeagueMatchesOfDayLeague,
} from "../../../store/actions/matchActions";
import { fetchCupOfDay } from "../../../store/actions/cupActions";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchLeagueMatchesOfDay: (aoiID: IDType, date: Date, sports: SportEnum) => {
    performAction({
      f: fetchLeagueMatchesOfDay,
      p: [dispatch, aoiID, dayToNumber(date), sports],
    });
  },
  fetchLeagueMatchesOfDayAndLeague: (leagueId: IDType, date: Date) => {
    performAction({
      f: fetchLeagueMatchesOfDayLeague,
      p: [dispatch, dayToNumber(date), leagueId],
    });
  },
  fetchCups: (aoiID: IDType, date: Date, sports: SportEnum) => {
    performAction({
      f: fetchCupOfDay,
      p: [dispatch, aoiID, dayToNumber(date), sports],
    });
  },
});

const props = (state: RootState, prevProps: { leagueId: IDType }) => {
  let leagueMatches: LeagueMatchSmallElement[] = [];
  let cups: TournamentMinimal[] = [];
  if (prevProps.leagueId === -1) {
    if (state.match.leagueMatches)
      leagueMatches = state.match.leagueMatches.response ?? [];
    if (state.cup.cups) cups = state.cup.cups.response ?? [];
  }
  let matches: MatchSmallElement[] = [];
  if (
    prevProps.leagueId !== -1 &&
    state.match.matchElements &&
    state.match.matchElements.id === prevProps.leagueId
  )
    matches = state.match.matchElements.response;
  return {
    leagueMatches,
    matches,
    cups,
    favorites: state.user.favorites,
    league: state.leagues.value.find((l) => l.id === prevProps.leagueId),
    sports: state.user.sportsType,
    ...prevProps,
  };
};

export const connector = connect(props, mapper);
// relo ad
