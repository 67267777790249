import { Action, Dispatch } from "redux";
import { IDType } from "../../config/types";
import { defaultGet } from "./all";
import { CupActionTypes, UserActionTypes } from "./types";
import client from "../../client";
import {
  GoalEventCreate,
  GoalEventPatch,
  MatchTournamentGroupCreate,
  MatchTournamentGroupPatch,
  MatchTournamentStageCreate,
  MatchTournamentStagePatch,
  SportEnum,
  TeamPatch,
  TournamentCreate,
  TournamentGroupCreate,
  TournamentGroupPatch,
  TournamentPatch,
  TournamentSecretPatch,
  TournamentStageCreate,
  TournamentStagePatch,
  TournamentTeamCreate,
} from "../../client/api";
import { numberToDay } from "../../config/utils";

export function fetchCupOfDay(
  dispatch: Dispatch<Action<CupActionTypes>>,
  aoi: IDType,
  date: number,
  sports: SportEnum
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUPS_SUCCESS,
    CupActionTypes.FETCH_CUPS_ERROR,
    CupActionTypes.FETCH_CUPS,
    client().getCupsOfDay,
    false,
    { id: aoi, date },
    aoi,
    numberToDay(date),
    sports
  );
}

export function fetchNewCup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP_NEW,
    client().getCup,
    false,
    false,
    id
  );
}
export function fetchCup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP,
    client().getCup,
    false,
    false,
    id
  );
}

export function fetchCupTeam(
  dispatch: Dispatch<Action<CupActionTypes>>,
  teamId: IDType,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_TEAM_SUCCESS,
    CupActionTypes.FETCH_TEAM_ERROR,
    CupActionTypes.FETCH_TEAM,
    client().fetchCupTeam,
    false,
    false,
    teamId,
    cupId
  );
}

export function fetchStandings(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STANDINGS_SUCCESS,
    CupActionTypes.FETCH_STANDINGS_ERROR,
    CupActionTypes.FETCH_STANDINGS,
    client().getCupStandings,
    false,
    { id },
    id
  );
}
export function fetchStages(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().getCupStages,
    false,
    { id },
    id
  );
}

export function fetchGroups(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUPS_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().getCupGroups,
    false,
    { id },
    id
  );
}

export function fetchCupPlayersTeam(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SQUAD_SUCCESS,
    CupActionTypes.FETCH_SQUAD_ERROR,
    CupActionTypes.FETCH_SQUAD,
    client().getSquad,
    false,
    { id },
    id
  );
}
export function addCupPlayer(
  dispatch: Dispatch<Action<CupActionTypes>>,
  teamId: IDType,
  givenName: string,
  familyName: string,
  jersey: number | undefined
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SQUAD_SUCCESS,
    CupActionTypes.FETCH_SQUAD_ERROR,
    CupActionTypes.FETCH_SQUAD,
    client().addCupPlayer,
    false,
    { id: teamId },
    teamId,
    givenName,
    familyName,
    jersey
  );
}
export function patchCupPlayer(
  dispatch: Dispatch<Action<CupActionTypes>>,
  teamId: IDType,
  playerId: IDType,
  givenName: string,
  familyName: string,
  jersey: number | undefined
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SQUAD_SUCCESS,
    CupActionTypes.FETCH_SQUAD_ERROR,
    CupActionTypes.FETCH_SQUAD,
    client().patchCupPlayer,
    false,
    { id: teamId },
    teamId,
    playerId,
    givenName,
    familyName,
    jersey
  );
}

export function deleteCupPlayer(
  dispatch: Dispatch<Action<CupActionTypes>>,
  teamId: IDType,
  playerId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SQUAD_SUCCESS,
    CupActionTypes.FETCH_SQUAD_ERROR,
    CupActionTypes.FETCH_SQUAD,
    client().deleteCupPlayer,
    false,
    { id: teamId },
    teamId,
    playerId
  );
}

export function fetchCupMatches(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_MATCHES_SUCCESS,
    CupActionTypes.FETCH_MATCH_ERROR,
    CupActionTypes.FETCH_MATCH,
    client().getCupMatches,
    false,
    { id: cupId },
    cupId
  );
}
export function fetchCupMatch(
  dispatch: Dispatch<Action<CupActionTypes>>,
  matchId: IDType,
  isGroup: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_MATCH_SUCCESS,
    CupActionTypes.FETCH_MATCH_ERROR,
    CupActionTypes.FETCH_MATCH,
    client().getCupMatch,
    false,
    false,
    matchId,
    isGroup
  );
}
export function addCupMatchGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  cupId: IDType,
  match: MatchTournamentGroupCreate,
  location?: string
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUP_MATCH_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().addCupMatchGroup,
    false,
    { id: cupId, date: stageId },
    stageId,
    match,
    location
  );
}
export function addCupMatchStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  cupId: IDType,
  match: MatchTournamentStageCreate,
  location?: string,
  prevMatchId?: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().addCupMatchStage,
    false,
    { id: cupId },
    stageId,
    match,
    location,
    prevMatchId
  );
}
export function connectCupMatchStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  matchId: IDType,
  nextMatchId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().connectCupMatchStage,
    false,
    { id: cupId },
    cupId,
    matchId,
    nextMatchId
  );
}
export function createQuarters(
  dispatch: Dispatch<Action<CupActionTypes>>,
  startTime: number,
  matchSectionDurationMinutes: number,
  cupId: IDType,
  twoMatchesAtATime: boolean,
  pauseBetweenMatches: number,
  matchSectionPauseDurationMinutes: number,
  isLooser: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().createQuarters,
    false,
    { id: cupId },
    startTime,
    matchSectionDurationMinutes,
    cupId,
    twoMatchesAtATime,
    pauseBetweenMatches,
    matchSectionPauseDurationMinutes,
    isLooser
  );
}
export function createEighters(
  dispatch: Dispatch<Action<CupActionTypes>>,
  startTime: number,
  matchSectionDurationMinutes: number,
  cupId: IDType,
  twoMatchesAtATime: boolean,
  pauseBetweenMatches: number,
  matchSectionPauseDurationMinutes: number,
  isLooser: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().createEighters,
    false,
    { id: cupId },
    startTime,
    matchSectionDurationMinutes,
    cupId,
    twoMatchesAtATime,
    pauseBetweenMatches,
    matchSectionPauseDurationMinutes,
    isLooser
  );
}
export function createSixteenth(
  dispatch: Dispatch<Action<CupActionTypes>>,
  startTime: number,
  matchSectionDurationMinutes: number,
  cupId: IDType,
  twoMatchesAtATime: boolean,
  pauseBetweenMatches: number,
  matchSectionPauseDurationMinutes: number,
  isLooser: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().createSixteenth,
    false,
    { id: cupId },
    startTime,
    matchSectionDurationMinutes,
    cupId,
    twoMatchesAtATime,
    pauseBetweenMatches,
    matchSectionPauseDurationMinutes,
    isLooser
  );
}
export function patchCupMatchStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  cupId: IDType,
  matchId: IDType,
  match: MatchTournamentStagePatch,
  location?: string
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGE_MATCH_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().patchCupMatchStage,
    false,
    { id: cupId, date: stageId },
    matchId,
    match,
    location
  );
}
export function patchCupMatchGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  cupId: IDType,
  matchId: IDType,
  group: MatchTournamentGroupPatch,
  location?: string
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUP_MATCH_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().patchCupMatchGroup,
    false,
    { id: cupId, date: stageId },
    matchId,
    group,
    location
  );
}
export function deleteCupMatchStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  matchId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().deleteCupMatchStage,
    false,
    { id: cupId },
    matchId,
    cupId
  );
}
export function deleteCupMatchGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  matchId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUPS_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().deleteCupMatchGroup,
    false,
    { id: cupId },
    matchId,
    cupId
  );
}
export function addCupGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  group: TournamentGroupCreate
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUP_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().addCupGroup,
    false,
    { id: cupId },
    cupId,
    group
  );
}

export function patchCupGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  groupId: IDType,
  group: TournamentGroupPatch,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUPS_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().patchCupGroup,
    false,
    { id: cupId },
    groupId,
    cupId,
    group
  );
}

export function deleteCupGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  groupId: IDType,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_GROUPS_SUCCESS,
    CupActionTypes.FETCH_GROUPS_ERROR,
    CupActionTypes.FETCH_GROUPS,
    client().deleteCupGroup,
    false,
    { id: cupId },
    groupId,
    cupId
  );
}
export function addCupStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  group: TournamentStageCreate
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGE_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().addCupStage,
    false,
    { id: cupId },
    cupId,
    group
  );
}

export function patchCupStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  stage: TournamentStagePatch,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().patchCupStage,
    false,
    { id: cupId },
    stageId,
    cupId,
    stage
  );
}

export function deleteCupStage(
  dispatch: Dispatch<Action<CupActionTypes>>,
  stageId: IDType,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STAGES_SUCCESS,
    CupActionTypes.FETCH_STAGES_ERROR,
    CupActionTypes.FETCH_STAGES,
    client().deleteCupStage,
    false,
    { id: cupId },
    stageId,
    cupId
  );
}

export function createCupTeam(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  team: TournamentTeamCreate
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP,
    client().createCupTeam,
    false,
    false,
    cupId,
    team
  );
}
export function patchCupTeam(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  teamId: IDType,
  team: TeamPatch
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_TEAM_SUCCESS,
    CupActionTypes.FETCH_TEAM_ERROR,
    CupActionTypes.FETCH_TEAM,
    client().patchCupTeam,
    false,
    false,
    cupId,
    teamId,
    team
  );
}
export function addTeamToGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  teamId: IDType,
  group: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STANDINGS_SUCCESS,
    CupActionTypes.FETCH_STANDINGS_ERROR,
    CupActionTypes.FETCH_STANDINGS,
    client().addTeamToCupGroup,
    false,
    { id: cupId },
    teamId,
    group
  );
}
export function removeCupTeamGroup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  teamId: IDType,
  stageId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_STANDINGS_SUCCESS,
    CupActionTypes.FETCH_STANDINGS_ERROR,
    CupActionTypes.FETCH_STANDINGS,
    client().removeCupTeamGroup,
    false,
    { id: cupId },
    teamId,
    stageId
  );
}

export function removeCupTeamCup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP,
    client().removeCupTeamCup,
    false,
    false,
    teamId,
    cupId
  );
}

export function requestCupManager(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  code: string,
  teamId: IDType
) {
  defaultGet(
    dispatch,
    UserActionTypes.FETCH_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.FETCH_ME,
    client().requestCupManager,
    false,
    false,
    cupId,
    code,
    teamId
  );
}

export function requestCupAdmin(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  code: string
) {
  defaultGet(
    dispatch,
    UserActionTypes.FETCH_SUCCESS,
    UserActionTypes.FETCH_ERROR,
    UserActionTypes.FETCH_ME,
    client().requestCupAdmin,
    false,
    false,
    cupId,
    code
  );
}
export function getTournamentSecrets(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SECRETS_SUCCESS,
    CupActionTypes.FETCH_SECRETS_ERROR,
    CupActionTypes.FETCH_SECRETS,
    client().getTournamentSecrets,
    false,
    { id: cupId },
    cupId
  );
}

export function createCup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cup: TournamentCreate,
  location?: string
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP_NEW,
    client().createCup,
    false,
    false,
    cup,
    location
  );
}
export function patchCup(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  cup: TournamentPatch,
  location?: string,
  pdf?: File
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP,
    client().patchCup,
    false,
    false,
    cupId,
    cup,
    location,
    pdf
  );
}
export function patchCupTimes(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  cup: TournamentPatch,
  groupIds: IDType[]
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_CUP_SUCCESS,
    CupActionTypes.FETCH_CUP_ERROR,
    CupActionTypes.FETCH_CUP,
    client().patchCupTimes,
    false,
    false,
    cupId,
    cup,
    groupIds
  );
}
export function patchCupSecrets(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType,
  cup: TournamentSecretPatch
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_SECRETS_SUCCESS,
    CupActionTypes.FETCH_SECRETS_ERROR,
    CupActionTypes.FETCH_SECRETS,
    client().patchCupSecrets,
    false,
    { id: cupId },
    cupId,
    cup
  );
}
export function getCupTopScorer(
  dispatch: Dispatch<Action<CupActionTypes>>,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_TOP_SCORER_SUCCESS,
    CupActionTypes.FETCH_TOP_SCORER_ERROR,
    CupActionTypes.FETCH_TOP_SCORER,
    client().getCupTopScorer,
    false,
    { id: cupId },
    cupId
  );
}

export function deleteCupEvent(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType,
  matchId: IDType,
  cupId: IDType
) {
  defaultGet(
    dispatch,
    CupActionTypes.DELETE_EVENT_SUCCESS,
    CupActionTypes.POST_EVENT_ERROR,
    CupActionTypes.POST_EVENT,
    client().deleteCupEvent,
    false,
    { id: matchId, date: cupId },
    id
  );
}
export function addCupEvent(
  dispatch: Dispatch<Action<CupActionTypes>>,
  matchId: IDType,
  event: GoalEventCreate,
  cupId: IDType,
  ignoreChecks = false
) {
  defaultGet(
    dispatch,
    CupActionTypes.POST_EVENT_SUCCESS,
    CupActionTypes.POST_EVENT_ERROR,
    CupActionTypes.POST_EVENT,
    client().addCupEvent,
    false,
    { id: matchId, date: cupId },
    matchId,
    event,
    ignoreChecks
  );
}
export function patchCupEvent(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType,
  matchId: IDType,
  cupId: IDType,
  event: GoalEventPatch
) {
  defaultGet(
    dispatch,
    CupActionTypes.POST_EVENT_SUCCESS,
    CupActionTypes.POST_EVENT_ERROR,
    CupActionTypes.POST_EVENT,
    client().patchCupEvent,
    false,
    { id: matchId, date: cupId },
    id,
    event
  );
}

export function startCupMatch(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType,
  minutes: number,
  lengthMs: number,
  isGroup: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_MATCH_SUCCESS,
    CupActionTypes.FETCH_MATCH_ERROR,
    CupActionTypes.FETCH_MATCH,
    client().startCupMatch,
    false,
    false,
    id,
    minutes,
    lengthMs,
    isGroup
  );
}

export function addOvertimeCupMatch(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType,
  endTime: Date,
  isGroup: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_MATCH_SUCCESS,
    CupActionTypes.FETCH_MATCH_ERROR,
    CupActionTypes.FETCH_MATCH,
    client().addOvertimeCupMatch,
    false,
    false,
    id,
    endTime,
    isGroup
  );
}

export function kickOutCupMatch(
  dispatch: Dispatch<Action<CupActionTypes>>,
  id: IDType,
  isGroup: boolean
) {
  defaultGet(
    dispatch,
    CupActionTypes.FETCH_MATCH_SUCCESS,
    CupActionTypes.FETCH_MATCH_ERROR,
    CupActionTypes.FETCH_MATCH,
    client().kickOutCupMatch,
    false,
    false,
    id,
    isGroup
  );
}
