import React, { useContext, useMemo } from "react";
import { ConnectedProps } from "react-redux";
import { GoalEvent, PlayerListElement } from "../../../client/api";
import PlayersInField from "./PlayersInField";
import { connector } from "./redux";
import { ClavaContext } from "../../../config/contexts";
import ChangeEventView from "./ChangeEventView";
import { translate } from "../../../config/translator";
import Field from "./Field";

const Lineup: React.FC<ConnectedProps<typeof connector>> = ({
  team1Id,
  team2Id,
  lineup1,
  lineup2,
  team1,
  team2,
  cardEvents,
  changeEvents,
  goalEvents,
  matchId,
}) => {
  const { l } = useContext(ClavaContext);
  const changeEvents1 = useMemo(
    () => changeEvents.filter((event) => event.teamId === team1Id),
    [changeEvents, team1Id]
  );
  const changeEvents2 = useMemo(
    () => changeEvents.filter((event) => event.teamId === team2Id),
    [changeEvents, team2Id]
  );
  const goalEvents1 = useMemo(
    () =>
      goalEvents.filter(
        (event) => event.teamId === team1Id && !!event.player
      ) as (Omit<GoalEvent, "player"> & { player: PlayerListElement })[],
    [goalEvents, team1Id]
  );
  const goalEvents2 = useMemo(
    () =>
      goalEvents.filter(
        (event) => event.teamId === team2Id && !!event.player
      ) as (Omit<GoalEvent, "player"> & { player: PlayerListElement })[],
    [goalEvents, team2Id]
  );
  const cardEvents1 = useMemo(
    () => cardEvents.filter((event) => event.teamId === team1Id),
    [cardEvents, team1Id]
  );
  const cardEvents2 = useMemo(
    () => cardEvents.filter((event) => event.teamId === team2Id),
    [cardEvents, team2Id]
  );
  return (
    <>
      <div className="m-4 relative">
        <Field team1={team1.emblem} team2={team2.emblem} />

        {lineup1 && (
          <PlayersInField
            lineup={lineup1}
            cardEvents={cardEvents1}
            goalEvents={goalEvents1}
            changeEvents={changeEvents1}
            rotated={matchId === -1}
            team={team1}
          />
        )}
        {lineup2 && (
          <PlayersInField
            lineup={lineup2}
            cardEvents={cardEvents2}
            goalEvents={goalEvents2}
            changeEvents={changeEvents2}
            team={team2}
            rotated
          />
        )}
      </div>
      <div className="border-b border-b-light-gray dark:border-b-light-gray-dark py-4 px-4">
        <h6 className="font-bold text-lg">{translate("changes", l)}</h6>
      </div>
      <div className="flex flex-col items-stretch justify-start px-4 pb-2">
        {Array(Math.max(changeEvents1.length, changeEvents2.length))
          .fill(0)
          .map((_, idx) => (
            <ChangeEventView
              change1={changeEvents1[idx]}
              change2={changeEvents2[idx]}
              key={`change-${changeEvents1[idx]?.id}-${changeEvents2[idx]?.id}`}
            />
          ))}
      </div>
    </>
  );
};

export default connector(Lineup);
