/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFollow } from '../models/UserFollow';
import type { UserFollowLeague } from '../models/UserFollowLeague';
import type { UserFollowMatch } from '../models/UserFollowMatch';
import type { UserFollowMatchPatch } from '../models/UserFollowMatchPatch';
import type { UserFollowPlayer } from '../models/UserFollowPlayer';
import type { UserFollowPlayerPatch } from '../models/UserFollowPlayerPatch';
import type { UserFollowTeam } from '../models/UserFollowTeam';
import type { UserFollowTeamPatch } from '../models/UserFollowTeamPatch';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserFollowService {
    /**
     * Get User Follow All
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static getUserFollowAllUserFollowAllGet(): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/all',
        });
    }
    /**
     * Get User Follow League
     * @returns UserFollowLeague Successful Response
     * @throws ApiError
     */
    public static getUserFollowLeagueUserFollowLeagueGet(): CancelablePromise<Array<UserFollowLeague>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/league',
        });
    }
    /**
     * Get User Follow Matches
     * @returns UserFollowMatch Successful Response
     * @throws ApiError
     */
    public static getUserFollowMatchesUserFollowMatchesGet(): CancelablePromise<Array<UserFollowMatch>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/matches',
        });
    }
    /**
     * Get User Follow Players
     * @returns UserFollowPlayer Successful Response
     * @throws ApiError
     */
    public static getUserFollowPlayersUserFollowPlayersGet(): CancelablePromise<Array<UserFollowPlayer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/players',
        });
    }
    /**
     * Get User Follow Teams
     * @returns UserFollowTeam Successful Response
     * @throws ApiError
     */
    public static getUserFollowTeamsUserFollowTeamsGet(): CancelablePromise<Array<UserFollowTeam>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/teams',
        });
    }
    /**
     * Patch User Follow Match
     * @param matchId
     * @param requestBody
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static patchUserFollowMatchUserFollowMatchMatchIdPatch(
        matchId: number,
        requestBody: UserFollowMatchPatch,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user_follow/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put User Follow Match
     * @param matchId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static putUserFollowMatchUserFollowMatchMatchIdPut(
        matchId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user_follow/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Follow Match
     * @param matchId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static deleteUserFollowMatchUserFollowMatchMatchIdDelete(
        matchId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_follow/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch User Follow Player
     * @param playerId
     * @param requestBody
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static patchUserFollowPlayerUserFollowPlayerPlayerIdPatch(
        playerId: number,
        requestBody: UserFollowPlayerPatch,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user_follow/player/{player_id}',
            path: {
                'player_id': playerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put User Follow Player
     * @param playerId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static putUserFollowPlayerUserFollowPlayerPlayerIdPut(
        playerId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user_follow/player/{player_id}',
            path: {
                'player_id': playerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Follow Player
     * @param playerId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static deleteUserFollowPlayerUserFollowPlayerPlayerIdDelete(
        playerId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_follow/player/{player_id}',
            path: {
                'player_id': playerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch User Follow Team
     * @param teamId
     * @param requestBody
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static patchUserFollowTeamUserFollowTeamTeamIdPatch(
        teamId: number,
        requestBody: UserFollowTeamPatch,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user_follow/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put User Follow Team
     * @param teamId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static putUserFollowTeamUserFollowTeamTeamIdPut(
        teamId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user_follow/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Follow Team
     * @param teamId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static deleteUserFollowTeamUserFollowTeamTeamIdDelete(
        teamId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_follow/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put User Follow League
     * @param leagueId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static putUserFollowLeagueUserFollowLeagueLeagueIdPut(
        leagueId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user_follow/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete User Follow League
     * @param leagueId
     * @returns UserFollow Successful Response
     * @throws ApiError
     */
    public static deleteUserFollowLeagueUserFollowLeagueLeagueIdDelete(
        leagueId: number,
    ): CancelablePromise<UserFollow> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user_follow/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Follow Count Player
     * @param playerId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUserFollowCountPlayerUserFollowCountPlayerPlayerIdGet(
        playerId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user_follow/count/player/{player_id}',
            path: {
                'player_id': playerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
