import { Reducer } from "redux";
import { MatchState } from "../constants";
import {
  dayToNumber,
  getMatchDate,
  mapToMatchSmallSmall,
  removeDuplicateMatchDays,
  replaceOrAddResponseSingle,
  sameDay,
} from "../../config/utils";
import { MatchActions, MatchActionTypes } from "../actions/types";
import {
  addMatchDays,
  addMatchDaysLeague,
  redoMatchesHomeScreen,
  redoMatchesLeagueScreen,
  redoMatchesTeamScreen,
} from "../../config/matchSmallUtils";

const initialState: MatchState = {
  matchDays: [],
  leagueMatchDays: [],
  matches: [],
  matchElements: undefined,
  leagueMatches: undefined,
  error: null,
  status: "idle",
  statusMatchDays: "idle",
};

const reducer: Reducer<MatchState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: MatchActions
) => {
  switch (action.type) {
    case MatchActionTypes.RESET: {
      return {
        matchDays: [],
        leagueMatchDays: [],
        matches: [],
        matchElements: undefined,
        leagueMatches: undefined,
        error: null,
        status: "idle",
        statusMatchDays: "idle",
      };
    }
    case MatchActionTypes.FETCH_LEAGUE_MATCHES_TEAM:
    case MatchActionTypes.FETCH_LEAGUE_MATCHES_DAY:
    case MatchActionTypes.FETCH_MATCHES_OF_LEAGUE:
    case MatchActionTypes.FETCH_MATCH:
    case MatchActionTypes.FETCH_LINEUP:
      return { ...state, status: "loading" };

    case MatchActionTypes.FETCH_MATCH_DAYS_LEAGUE:
    case MatchActionTypes.FETCH_MATCH_DAYS: {
      return { ...state, statusMatchDays: "loading" };
    }
    case MatchActionTypes.REFRESH_MATCH: {
      const aoiID = action.payload.id;
      const date = dayToNumber(getMatchDate(action.payload.response));
      const matchId = action.payload.response.id;
      const { league } = action.payload.response;
      const leagueId = league.id;
      const matchesIdx = state.matches.findIndex((m) => m.id === matchId);
      const { leagueMatches, matchElements } = state;

      if (
        leagueMatches &&
        leagueMatches.id === aoiID &&
        leagueMatches.date === date
      ) {
        const lmMIdx = leagueMatches.response.findIndex(
          (lmm) => lmm.leagueTournamentId === leagueId
        );
        if (lmMIdx) {
          const matchListElementIdx = leagueMatches.response[
            lmMIdx
          ].matches.findIndex((m) => m.id === matchId);

          if (matchListElementIdx !== -1) {
            leagueMatches.response[lmMIdx].matches[matchListElementIdx].goal1 =
              action.payload.response.goal1;
            leagueMatches.response[lmMIdx].matches[matchListElementIdx].goal2 =
              action.payload.response.goal2;
          }
        }
      }

      if (matchElements && matchElements.id === leagueId) {
        const mIdx = matchElements.response.findIndex((m) => m.id === matchId);
        if (mIdx !== -1) {
          matchElements.response[mIdx].goal1 = action.payload.response.goal1;
          matchElements.response[mIdx].goal2 = action.payload.response.goal2;
        }
      }
      return {
        ...state,
        status: "idle",
        matches:
          matchesIdx === -1
            ? state.matches
            : state.matches.fill(
                action.payload.response,
                matchesIdx,
                matchesIdx + 1
              ),
        matchElements,
        leagueMatches,
      };
    }
    case MatchActionTypes.REFRESH_MATCH_DAYS: {
      const { match } = action.payload;
      const { league } = match;
      const leagueId = league.id;
      const { aoiID, addDate, removeDate } = action.payload;
      const newMatchDays = addMatchDays(state.matchDays, addDate, removeDate);
      const newLeagueMatchDays = addMatchDaysLeague(
        leagueId,
        state.leagueMatchDays,
        addDate,
        removeDate
      );
      const date = dayToNumber(getMatchDate(match));
      redoMatchesHomeScreen(state.leagueMatches, match, aoiID, date);
      redoMatchesLeagueScreen(state.matchElements, match, date);
      redoMatchesTeamScreen(state.matchElements, match);

      return {
        ...state,
        status: "idle",
        matches: replaceOrAddResponseSingle(match, state.matches),
        leagueMatchDays: newLeagueMatchDays,
        matchDays: newMatchDays,
      };
    }
    case MatchActionTypes.FETCH_LINEUP_SUCCESS: {
      const { id, date, response } = action.payload;
      return {
        ...state,
        status: "idle",
        matches: state.matches.map((m) =>
          m.id === id
            ? {
                ...m,
                lineupTeam1: date === 1 ? response : m.lineupTeam1,
                lineupTeam2: date === 2 ? response : m.lineupTeam2,
              }
            : m
        ),
      };
    }
    case MatchActionTypes.FETCH_SUCCESS_MATCH_DAYS: {
      return {
        ...state,
        statusMatchDays: "idle",
        matchDays: removeDuplicateMatchDays(
          state.matchDays.concat(action.payload.map((m) => new Date(m)))
        ),
      };
    }
    case MatchActionTypes.REMOVE_MATCH_DAY: {
      return {
        ...state,
        statusMatchDays: "idle",
        matchDays: state.matchDays.filter((d) => !sameDay(d, action.payload)),
        leagueMatchDays: state.leagueMatchDays.map((lm) => ({
          ...lm,
          response: lm.response.filter((d) => !sameDay(d, action.payload)),
        })),
      };
    }
    case MatchActionTypes.RESTORE_MATCHDAYS: {
      return {
        ...state,
        statusMatchDays: "idle",
        matchDays: [],
        leagueMatchDays: [],
      };
    }
    case MatchActionTypes.FETCH_SUCCESS_MATCH_DAYS_LEAGUE: {
      const league = action.payload.id;
      let notFetchedJet = true;
      const { leagueMatchDays } = state;
      leagueMatchDays.forEach((matchDay, index) => {
        if (matchDay.id === league) {
          leagueMatchDays[index].response.concat(action.payload.response);
          notFetchedJet = false;
        }
      });
      if (notFetchedJet) leagueMatchDays.push(action.payload);
      return {
        ...state,
        statusMatchDays: "idle",
        leagueMatchDays: leagueMatchDays.map((leagueMatchDay) => ({
          id: leagueMatchDay.id,
          fetchDate: new Date(),
          response:
            league === leagueMatchDay.id
              ? removeDuplicateMatchDays(leagueMatchDay.response)
              : leagueMatchDay.response,
        })),
      };
    }
    case MatchActionTypes.FETCH_SUCCESS_MATCHES: {
      return {
        ...state,
        status: "idle",
        matches: replaceOrAddResponseSingle(action.payload, state.matches),
      };
    }
    case MatchActionTypes.FETCH_SUCCESS_LEAGUE_MATCHES: {
      return {
        ...state,
        status: "idle",
        leagueMatches: {
          ...action.payload,
          response: action.payload.response.map((lm) => ({
            ...lm,
            matches: lm.matches.map(mapToMatchSmallSmall),
          })),
        },
      };
    }
    case MatchActionTypes.FETCH_SUCCESS_MATCHES_OF_LEAGUE: {
      return {
        ...state,
        status: "idle",
        matchElements: {
          ...action.payload,
          response: action.payload.response.map(mapToMatchSmallSmall),
        },
      };
    }
    case MatchActionTypes.FETCH_ERROR: {
      return {
        ...state,
        status: "failed",
        statusMatchDays: "failed",
        error: action.payload,
      };
    }

    case MatchActionTypes.REFRESH:
      return {
        ...state,
        status: "idle",
        matches: state.matches.filter((m) => m.id !== action.payload),
      };
    default: {
      return state;
    }
  }
};

export { reducer as matchReducer };
// reload
