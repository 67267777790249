import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ConnectedProps } from "react-redux/es/exports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFutbol } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router";
import { faHockeyPuck, faLocation } from "@fortawesome/pro-solid-svg-icons";
import { connector } from "./redux";
import { SportEnum } from "../../../client/api";
import { showTranslated, translate } from "../../../config/translator";
import { ClavaContext } from "../../../config/contexts";
import ClavaDropdown from "../ClavaDropdown";
import { IDType } from "../../../config/types";

const SportsTypePicker: React.FC<ConnectedProps<typeof connector>> = ({
  currentSportsType,
  changeSportsType,
  onChange,
  aois,
  getAois,
  changeAoi,
  aoisStatus,
}) => {
  const { l, user } = useContext(ClavaContext);
  const aoi = useMemo(() => user.areaOfInterest.id, [user.areaOfInterest.id]);
  useEffect(() => {
    if (aois.length === 0 && aoisStatus === "idle") {
      getAois();
    }
  }, [aois.length, aoisStatus, getAois]);
  const navigation = useNavigate();
  const onPressSoccer = useCallback(() => {
    if (onChange) {
      onChange();
    }
    changeSportsType(SportEnum.SOCCER);
    navigation("/home");
  }, [changeSportsType, navigation, onChange]);
  const setAoi = useCallback(
    (id: IDType | undefined) => {
      const a = aois.find((ao) => ao.id === id);
      if (a) {
        changeAoi(a);
      }
    },
    [aois, changeAoi]
  );
  const aoiItems = useMemo(
    () =>
      aois.map((ao) => ({
        key: ao.id,
        label: showTranslated(ao.name, l),
      })),
    [aois, l]
  );
  const linkClasses = useCallback(
    ({ isActive, isPending }: { isActive: boolean; isPending: boolean }) => {
      if (isActive)
        return "flex flex-row items-center text-primary p-4 border-4 border-l-transparent border-t-transparent border-r-transparent border-b-primary";
      if (isPending)
        return "flex flex-row items-center text-primary p-4 border-4 border-transparent";
      return "flex flex-row items-center p-4 border-4 border-transparent hover:text-primary";
    },
    []
  );
  const onPressHockey = useCallback(() => {
    if (onChange) {
      onChange();
    }
    changeSportsType(SportEnum.HOCKEY);
    navigation("/home");
  }, [changeSportsType, navigation, onChange]);
  return (
    <div className="flex flex-row items-stretch justify-start border-b border-b-light-gray dark:border-b-light-gray-dark">
      <div className="py-2 px-4 border-r border-r-light-gray dark:border-r-light-gray-dark flex flex-row items-center justify-start">
        <div className="rounded-xl bg-input-bg dark:bg-input-bg-dark w-8 h-8 flex flex-col items-center justify-center">
          <FontAwesomeIcon icon={faLocation} className="w-4 h-4" />
        </div>
        <div className="flex flex-col items-start justify-between">
          <span className="px-2">{translate("areaOfInterest", l)}</span>
          <ClavaDropdown
            onChange={setAoi}
            items={aoiItems}
            transparent
            primary
            value={aoi}
            withoutChoose
          />
        </div>
      </div>
      <div className="flex flex-row items-center px-4">
        <button
          type="button"
          onClick={onPressSoccer}
          className={`flex flex-row items-center p-4 border-4 hover:text-primary border-l-transparent border-t-transparent border-r-transparent ${
            currentSportsType === SportEnum.SOCCER
              ? "border-b-primary !text-primary"
              : "border-b-transparent"
          }`}
        >
          <FontAwesomeIcon icon={faFutbol} className="w-4 h-4 mr-2" />
          <span>{translate(SportEnum.SOCCER, l)}</span>
        </button>
        <button
          type="button"
          onClick={onPressHockey}
          className={`flex flex-row items-center p-4 border-4 hover:text-primary border-l-transparent border-t-transparent border-r-transparent ${
            currentSportsType === SportEnum.HOCKEY
              ? "border-b-primary !text-primary"
              : "border-b-transparent"
          }`}
        >
          <FontAwesomeIcon icon={faHockeyPuck} className="w-4 h-4 mr-2" />
          <span>{translate(SportEnum.HOCKEY, l)}</span>
        </button>
      </div>
    </div>
  );
};
SportsTypePicker.displayName = "SportsTypePicker";
export default connector(SportsTypePicker);
