/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AdPositionEnum {
    HOME_MATCH = 'HOME_MATCH',
    LEAGUE_MATCH_MATCH = 'LEAGUE_MATCH_MATCH',
    MATCH_HISTORY_BOTTOM = 'MATCH_HISTORY_BOTTOM',
    MATCH_FEED = 'MATCH_FEED',
    USER_FEED = 'USER_FEED',
    TEAM_OF_THE_WEEK = 'TEAM_OF_THE_WEEK',
    STANDINGS = 'STANDINGS',
    NEWS_ADS = 'NEWS_ADS',
    ADMOB_PLACEHOLDER = 'ADMOB_PLACEHOLDER',
    HOME_MATCH_DOUBLE = 'HOME_MATCH_DOUBLE',
}
