import React from "react";
import { NavLink } from "react-router-dom";
import { AdPositionEnum, TournamentMinimal } from "../../../../client/api";
import { IDType, MatchSmallElement } from "../../../../config/types";
import ClavaAd from "../../ClavaAd";
import MatchSmall from "../../Match/Small";
import CupSmall from "../../Cup/CupSmall";

export declare type SectionItem =
  | string
  | (MatchSmallElement & { idx: number; idx2: number })
  | TournamentMinimal;

export declare type SectionTitle = { name: string; id: IDType | undefined };

export declare type Section = { title: SectionTitle; data: SectionItem[] };

const LeagueMatch: React.FC<{
  item: SectionItem;
  type: "league" | "home" | "team";
}> = ({ item, type }) => {
  if (typeof item === "string") {
    if (type === "home" || type === "league") return null;

    return (
      <div className="my-2 mx-24">
        <ClavaAd
          type={AdPositionEnum.HOME_MATCH}
          rounded
          priority={item.indexOf("priority") !== -1}
        />
      </div>
    );
  }
  if ("dateFrom" in item) {
    return <CupSmall cup={item} />;
  }
  return <MatchSmall idx={item.idx} idx2={item.idx2} type={type} />;
};

const LeagueMatchTitle: React.FC<{ item: SectionTitle }> = ({ item }) => {
  if (item.id) {
    return (
      <NavLink
        to={`/league/${item.id}`}
        className="text-primary font-bold px-4"
      >
        {item.name}
      </NavLink>
    );
  }
  return <div className="text-primary font-bold px-4">{item.name}</div>;
};

const LeagueMatchSection: React.FC<{
  section: Section;
  type: "league" | "home" | "team";
}> = ({ section, type }) => (
  <div className="mt-4">
    <LeagueMatchTitle item={section.title} />
    {section.data.map((item) => (
      <LeagueMatch
        item={item}
        type={type}
        key={`league-match-item-${typeof item === "string" ? item : item.id}`}
      />
    ))}
  </div>
);

export default LeagueMatchSection;
