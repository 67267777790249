import { IDType } from "./types";

const routes = [
  "/",
  "/home",
  "/home/:date",
  "/home/match/:matchId",
  "/home/match/:matchId/:view",
  "/home/tournament/:tournamentId",
  "/league/:leagueId/:date",
  "/league/:leagueId/match/:matchId",
  "/league/:leagueId/match/:matchId/:view",
  "/league/:leagueId/:date/match/:matchId",
  "/league/:leagueId/:date/match/:matchId/:view",
];

export declare type RouteParams = {
  date?: number;
  leagueId?: IDType;
  feedId?: IDType;
  matchId?: IDType;
  tournamentId?: IDType;
  view?: "highlights" | "lineup" | "table" | "statistics";
};

export function parseParams(params: RouteParams, old?: RouteParams): string {
  params = { ...old, ...params };
  const date = "date" in params && !!params.date ? `/${params.date}` : "";
  const view = "view" in params && !!params.view ? `/${params.view}` : "";
  const match =
    "matchId" in params && !!params.matchId
      ? `/match/${params.matchId}${view}`
      : "";
  const cup =
    "tournamentId" in params && !!params.tournamentId
      ? `/tournament/${params.tournamentId}${view}`
      : "";

  return `/${
    "leagueId" in params && !!params.leagueId
      ? `league/${params.leagueId}${date}${match}`
      : `home${date}${match}${cup}`
  }`;
}
// reload
