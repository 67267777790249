import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  Row,
} from "reactstrap";
import { ConnectedProps } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { NavLink } from "react-router-dom";
import { ClavaContext } from "../../../config/contexts";
import { translate } from "../../../config/translator";
import { connector } from "./redux";
import {
  isContentManager,
  isInsider,
  isRegistered,
  isTeamInsider,
} from "../../../config/utils";
import LeftSide from "./LeftSide";
import ClavaButton from "../../components/ClavaButton";
import {
  BETA_ENDPOINT,
  DEV_ENDPOINT,
  PROD_ENDPOINT,
  STAG_ENDPOINT,
} from "../../../config/constants";
import client from "../../../client";
import ClavaDropdown, { DropdownItem } from "../../components/ClavaDropdown";

const DeleteFadeTrans = {
  timeout: 250,
  baseClass: "delete-account",
};
const endpoints = {
  Production: PROD_ENDPOINT,
  Stag: STAG_ENDPOINT,
  Test: BETA_ENDPOINT,
  Development: DEV_ENDPOINT,
};
const endpointItems: DropdownItem<keyof typeof endpoints>[] = [
  { label: "Production", key: "Production" },
  { label: "Stag", key: "Stag" },
  { label: "Test", key: "Test" },
  { label: "Development", key: "Development" },
];

const Profile: React.FC<ConnectedProps<typeof connector>> = ({
  changeUsernameStatus,
  logout,
  deleteAccount,
  setEndpoint,
}) => {
  const { l, user } = useContext(ClavaContext);
  const isPremium = useMemo(() => user.premium, [user]);
  const insider = useMemo(() => isInsider(user, undefined), [user]);
  const teamInsider = useMemo(() => isTeamInsider(user), [user]);
  const [username, setUsername] = useState<string>(user.username);
  const registered = useMemo(() => isRegistered(user), [user]);
  const [deleteAccountModal, setDeleteAccount] = useState<boolean>(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState<
    keyof typeof endpoints
  >(
    client().getEndpoint() === PROD_ENDPOINT
      ? "Production"
      : client().getEndpoint() === STAG_ENDPOINT
      ? "Stag"
      : client().getEndpoint() === BETA_ENDPOINT
      ? "Test"
      : "Development"
  );
  const onChangeUsername = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setUsername(e.target.value);
    },
    []
  );
  const onDeleteAccount = useCallback(() => {
    setDeleteAccount(true);
  }, []);
  const onCancelDeleteAccount = useCallback(() => {
    setDeleteAccount(false);
  }, []);
  const onChangeEndpoint = useCallback(
    (item: keyof typeof endpoints | undefined) => {
      if (item) {
        setSelectedEndpoint(item);
        if (item in endpoints) {
          const endpoint = endpoints[item];
          setEndpoint(endpoint);
        }
      }
    },
    [setEndpoint]
  );
  return (
    <div className="flex flex-row items-stretch justify-start">
      <LeftSide />
      <div className="flex-1 m-4 flex flex-col items-center justify-between">
        <div className="mt-6 flex flex-col items-center justify-start">
          {teamInsider ? (
            <h6 className="text-primary font-semibold">
              {translate("teamInsider", l)}
            </h6>
          ) : insider ? (
            <h6 className="text-primary font-semibold">
              {translate("insider", l)}
            </h6>
          ) : null}
          {isPremium && (
            <span className="text-primary font-semibold">
              {translate("premium", l)}
            </span>
          )}
          {user.photo ? (
            <img
              src={user.photo.url}
              className="w-32 h-32 rounded-full my-4"
              alt="Profile"
            />
          ) : (
            <FontAwesomeIcon
              icon={faUserCircle}
              className="text-primary mt-2 w-32 h-32 my-4"
            />
          )}
          <FormGroup className="text-center items-center mt-2">
            {changeUsernameStatus === "usernameGiven" && (
              <span className="text-danger">
                {translate(changeUsernameStatus, l)}
              </span>
            )}
            <InputGroup className="only-focus">
              <Input
                onChange={onChangeUsername}
                value={username}
                disabled
                name="username"
                className="text-center"
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className="flex flex-col items-center justify-start">
          {isContentManager(user) && (
            <>
              <ClavaDropdown
                value={selectedEndpoint}
                items={endpointItems}
                onChange={onChangeEndpoint}
                withoutChoose
              />
              <div className="my-2">
                <NavLink
                  to="/backoffice"
                  className="rounded-[15px] py-2 px-8 flex flex-row items-center justify-center border-0 bg-primary active:bg-btn-active hover:bg-btn-active text-font-dark"
                >
                  <h5>Backoffice</h5>
                </NavLink>
              </div>
            </>
          )}
          {!user.emailConfirmed && (
            <div className="my-2 text-sm-center">
              <NavLink
                to="/register"
                className="rounded-[15px] py-2 px-4 flex flex-row items-center justify-center border-0 bg-btn active:bg-btn-active hover:bg-btn-active text-font-dark"
              >
                {translate(user.email ? "confirmMailShort" : "register", l)}
              </NavLink>
              {!registered && (
                <div>
                  <NavLink
                    to="/login"
                    className="text-decoration-underline text-muted"
                  >
                    <span>{`${translate("alreadyRegistered", l)} ${translate(
                      "login",
                      l
                    )}`}</span>
                  </NavLink>
                </div>
              )}
            </div>
          )}

          {registered && (
            <div className="my-2 mx-auto">
              <ClavaButton onClick={logout} secondary>
                {translate("logout", l)}
              </ClavaButton>
            </div>
          )}
          {registered && (
            <div className="my-2 mx-auto">
              <ClavaButton secondary onClick={onDeleteAccount}>
                {translate("deleteAccount", l)}
              </ClavaButton>
            </div>
          )}
          <span className="text-sm text-center">Clava Sports &copy; 2022</span>
        </div>
        <Modal
          isOpen={deleteAccountModal}
          modalTransition={DeleteFadeTrans}
          backdrop
          unmountOnClose={false}
        >
          <div className="default-modal-content">
            <h3>{translate("deleteAccount", l)}</h3>
            <p>{translate("deleteAccountCont", l)}</p>
            <Row>
              <Col xs={12} md={6}>
                <Button color="secondary" onClick={deleteAccount}>
                  <span>{translate("yes", l)}</span>
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button color="primary" onClick={onCancelDeleteAccount}>
                  <span>{translate("no", l)}</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default connector(Profile);
// reloa  d
