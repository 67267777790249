import React, { useContext, useMemo } from "react";
import {
  League,
  LeagueListElement,
  Team,
  TeamListElement,
  TeamListWithoutLeagues,
  TeamSmall,
} from "../../../../client/api";
import { getMainLeague } from "../../../../config/utils";

import MatchScoreDisplay from "../../Match/MatchScoreDisplay";
import { ClavaContext } from "../../../../config/contexts";
import { showTranslated } from "../../../../config/translator";
import ClavaImage from "../../ClavaImage";

type TeamNameProps = {
  team: Team | TeamListElement | TeamListWithoutLeagues | TeamSmall;
  live?: boolean;
  goal?: number;
  primary?: boolean;
  bold?: boolean;
  admin?: boolean;
  leagues?: League[] | LeagueListElement[];
};

const TeamName: React.FC<TeamNameProps> = ({
  team,
  admin,
  live,
  primary,
  bold,
  goal,
  leagues,
}) => {
  const { l } = useContext(ClavaContext);
  const mainLeague = useMemo(
    () => (leagues ? getMainLeague(leagues) : undefined),
    [leagues]
  );
  return (
    <div className="flex flex-row items-center justify-between my-1">
      <div className="flex flex-row items-center justify-start">
        <ClavaImage
          image={"emblem" in team ? team.emblem : undefined}
          imageUrl={"emblemUrl" in team ? team.emblemUrl : undefined}
          width={24}
          className="mr-2"
        />
        <span
          className={
            (primary ? "text-primary " : "") + (bold ? "font-bold " : "")
          }
        >{`${admin ? `[${team.id}]` : ""}${showTranslated(
          team.name,
          l
        )}`}</span>
        {mainLeague && (
          <span className={primary ? "text-primary" : "font-light"}>
            {`${admin ? `[${mainLeague.id}]` : ""}${showTranslated(
              mainLeague.name,
              l
            )}`}
          </span>
        )}
      </div>
      {goal !== undefined ? (
        <div>
          <MatchScoreDisplay
            goal1={goal}
            className={`bold ${live ? " text-red" : ""}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TeamName;
