import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { IDType, MatchSmallElement } from "../../../config/types";
import { RootState } from "../../../store";
import { fetchMatch } from "../../../store/actions/matchActions";
import { performAction } from "../../../store/actions/all";

const mapper = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getMatch: (id: IDType) => {
    performAction({ f: fetchMatch, p: [dispatch, id] });
  },
});

const props = (state: RootState, prevProps: { match: MatchSmallElement }) => ({
  fullMatch: state.match.matches.find((m) => m.id === prevProps.match.id),
  thisMatchId: prevProps.match.id,
  ...prevProps,
});

export const connector = connect(props, mapper);
